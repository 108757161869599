import React from "react";

export const CajaInputs = ({ titulo, contenido, margenBot = "0px" }) => {
  return (
    <div className="contenedor-inputs" style={{ marginBottom: margenBot }}>
      <div className="header">
        <h5>{titulo}</h5>
      </div>
      <div className="body-caja" style={{ padding: "15px" }}>
        {contenido}
      </div>
    </div>
  );
};
