import React, { useRef, useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { SiHtml5, SiJavascript, SiCss3, SiMarkdown } from "react-icons/si";
import { FaNpm } from "react-icons/fa";
import { Tree } from "react-arborist";
import { useFirebase } from "app/firebase/context";
import {
  SearchArray,
  flattenRoutes,
  extractPaths,
  transformPaths,
} from "app/components/helpers/Various";
import { FaFolderMinus } from "react-icons/fa";
import { Dialogo } from "app/components/helpers/Dialogo";
import { get, set, push, update, remove } from "firebase/database";
import { IoInformationCircleOutline } from "react-icons/io5";
import BackButton from "app/components/helpers/BackButton";
import Switch from "react-switch";
import {
  ref,
  getDownloadURL,
  deleteObject,
  getStorage,
  uploadBytes,
  uploadString,
  listAll,
  copyTo,
  updateMetadata,
  getMetadata,
} from "firebase/storage";
import {
  getSupportedFileTypes,
  getUploadLimits,
  truncarCadena,
  handleDescargar,
} from "./funcionesDocs";

import { getTituloJunta } from "app/views/juntas/FuncionesJuntas";
import Tooltip from "react-tooltip-lite";
import obtenerIconoPorExtension from "app/components/helpers/IconosFicheros";
import Node from "./Node";
import { useStore } from "react-redux";
import { TbFolderPlus } from "react-icons/tb";
import { AiOutlineFileAdd } from "react-icons/ai";
import {
  ArchiveOutlined,
  CheckBox,
  CollectionsBookmarkRounded,
} from "@material-ui/icons";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { toast } from "react-toastify";
import { findNodeById, renameNodeById } from "app/components/helpers/Various";
import { generateStackData } from "@nivo/generators";
import Emulador from "app/components/EmuladorApp/Emulador";
import ModalComponent from "app/components/ModalComponent/ModalComponent";
import FormularioEnvioAvisos from "app/components/ModalComponent/FormularioEnvioAvisos";
import { FaFolderPlus } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { TextField } from "@mui/material";
import { MdFolderDelete } from "react-icons/md";
import { LuFolderEdit } from "react-icons/lu";
import { MultiSelect } from "react-multi-select-component";
import { identity, isObject } from "lodash";
import { es } from "date-fns/locale";
import { array } from "prop-types";

const Documentos = () => {
  const [archivoAvisos, setArchivoAvisos] = useState("");
  const [pathAvisos, setPathAvisos] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogModificar, setShowDialogModificar] = useState(false);
  const [showDialogSubida, setShowDialogSubida] = useState(false);
  const [nombreCarpetaModificar, setNombreCarpetaModificar] = useState("");
  const [pathCarpetaModificar, setPathCarpetaModificar] = useState("");
  const [esGeneral, setEsGeneral] = useState(false);
  let { Identificador, docusUsuario, IdJunta } = useParams();

  const nombreDocumentacionGeneral = "10 Documentación general";
  const nombreJuntasPropietarios = "Juntas de Propietarios";

  Identificador =
    Identificador === "documentaciongeneral"
      ? nombreDocumentacionGeneral
      : Identificador;

  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const firebase = useFirebase();
  const [archivosEnCarpetaArborist, setArchivosEnCarpetaArborist] = useState({
    carpeta: "",
    archivos: [],
    path: "",
  });
  const deshabilitadoRef = useRef(null);
  const [datosIniciales, setDatosIniciales] = useState({
    nombreInicial: "",
    transferibleInicial: false,
  });
  const [deshabilidato, setDeshabilidato] = useState(true);
  const [gData, setGData] = useState([]);
  const [inputValue, setInputValue] = useState(
    nombreCarpetaModificar.split(".")[0]
  );
  const horaActual = new Date().toLocaleTimeString();
  const [loading, setLoading] = useState(true);
  const [checkedTransferible, setCheckedTransferible] = useState(false);
  const [term, setTerm] = useState("");
  const treeRef = useRef(null);
  const [mostrarInput, setMostrarInput] = useState(false);
  const [nombreCarpeta, setNombreCarpeta] = useState("");
  const functions = getFunctions(
    firebase.app,
    process.env.REACT_APP_FIREBASE_ZONE
  );
  const [seleccionNuevaCarpeta, setSeleccionNuevaCarpeta] = useState("");
  let [rolesOptions, setRolesOptions] = useState([]);
  const [soloPresi, setSoloPresi] = useState([]);
  const locationSelector = {
    allItemsAreSelected: "Todos los roles han sido seleccionados.",
    clearSearch: "Limpiar búsqueda",
    clearSelected: "Limpiar seleccionado",
    noOptions: "No hay roles",
    search: "Buscar",
    selectAll: "Seleccionar todos",
    selectAllFiltered: "Seleccionar todos (filtrado)",
    selectSomeItems: "Visible sólo para...",
    create: "Crear",
  };
  const [respuesta, setRespuesta] = useState(null); // Estado para almacenar la respuesta seleccionada
  const [showDialogForm, setshowDialogForm] = useState(false);
  const [refrescarSoloPresi, setRefrescarSoloPresi] = useState(false);

  const [estaArrastrandoEncima, setEstaArrastrandoEncima] = useState(false);

  const abrirDiagolo = () => {
    setshowDialogForm(true);
  };

  const manejarArrastreEncima = (event) => {
    event.preventDefault();
    setEstaArrastrandoEncima(true);
  };

  const manejarArrastreFuera = () => {
    setEstaArrastrandoEncima(false);
  };

  const [checkHabilitar, setCheckHabilitar] = useState({
    transferible: false,
    nombreCambiar: "",
  });
  const handleChangeRespuesta = (e) => {
    setRespuesta(e.target.value); // Actualizar el estado con la respuesta seleccionada
  };
  const refresh = () => {
    setRefrescar(!refrescar);

    //toast.info('Datos refrescados', getCustomToastOptions(toast, 'info'));
  };
  const [refrescar, setRefrescar] = useState(false);
  const [refrescarRoles, setRefrescarRoles] = useState(false);
  const [nombreComu, setNombreComu] = useState("");
  const [dialogoContenido, setDialogoContenido] = useState({
    titulo: "",
    cuerpo: "",
    ejecutarFuncion: "",
    path: "",
    inputElement: false,
    nombreCambiar: "",
  });

  let rootFolder =
    docusUsuario == "true" ? `usuarios/${Identificador}/` : Identificador;

  const loadDocuments = async () => {
    try {
      let treeElements = await firebase.getFolderContents(rootFolder);
      // Añado la carpeta 10 Documentacion General si no estamos en la documentación general desde el despacho
      if (
        Identificador !== nombreDocumentacionGeneral &&
        docusUsuario !== "true"
      ) {
        let docuGeneral = await firebase.getFolderContents("");
        docuGeneral = docuGeneral.filter(
          (carpeta) => carpeta.id === nombreDocumentacionGeneral
        );
        treeElements = treeElements.concat(docuGeneral);
      }
      /*
      treeElements.map(rama => {
        if(rama.id===nombreDocumentacionGeneral){
          rama.nameUPD = "Documentación general";
        }
      });
      */
      setGData(treeElements);

      const firstLevelElements = [];
      for (const node of treeElements) {
        firstLevelElements.push(node.name);
      }

      setArchivosEnCarpetaArborist({
        carpeta: Identificador + "/",
        archivos: firstLevelElements,
        path: rootFolder + "/",
        firstLevel: true,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const refrescarCarpetaNueva = async () => {
    // Llamada a la función al renderizar el componente
    await loadDocuments();
  };

  const obtenerNombreComunidad = async () => {
    try {
      const snapshot = await get(
        firebase.getReferencia(`Comunidad/${Identificador}`, null)
      );

      try {
        const nombreComu = snapshot.val();
        setNombreComu(nombreComu.Nombre);
        // Si necesitas más código aquí...
      } catch (error) {
        console.error("Ha ocurrido un error:", error);
      }
      //const aplanado = flattenRoutes(carpetas);
    } catch (error) {}
  };

  const cargarSoloPresi = async () => {
    try {
      const snapshot = await get(
        firebase.getReferencia(`SoloPresi/${Identificador}`, null)
      );

      const carpetas = {};
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        carpetas[childKey] = childData;
      });

      //const aplanado = flattenRoutes(carpetas);

      // Añado la carpeta 10 Documentacion General si no estamos en la documentación general desde el despacho
      if (Identificador !== nombreDocumentacionGeneral) {
        const solopresigeneral = await get(
          firebase.getReferencia(
            `SoloPresi/${nombreDocumentacionGeneral}`,
            null
          )
        );

        const carpetasGeneral = {};

        solopresigeneral.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
          carpetasGeneral[childKey] = childData;
        });

        if (Object.keys(carpetasGeneral).length > 0) {
          carpetas[nombreDocumentacionGeneral] = carpetasGeneral;
        }
      }

      setSoloPresi(carpetas);
    } catch (error) {}
  };

  const [showModal, setShowModal] = useState(true);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleCloseModificar = () => {
    setShowDialogModificar(false);
  };
  const cargarRoles = async () => {
    try {
      const snapshot = await get(firebase.getReferencia("Roles2", null));
      let datos = [];
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        childData.idInterno = childKey;
        datos.push({
          label: childData.Nombre,
          value: childData.Nombre,
        });
      });
      setRolesOptions(datos);
    } catch (error) {}
  };

  const [archivosTraferibles, setArchivosTraferibles] = useState([]);

  const [ObtenerSoloPresi, setObtenerSoloPresi] = useState([]); // Estado para almacenar la respuesta seleccionada

  const ObtenerArchivosTransferibles = async () => {
    let arrayDatetes = [];
    for (let archivo of archivosEnCarpetaArborist.archivos) {
      if (archivo.includes(".")) {
        const ruta = archivosEnCarpetaArborist.path + "/" + archivo;
        const forestRef = ref(firebase.storage, ruta);

        try {
          const metadata = await getMetadata(forestRef);
          arrayDatetes.push({
            archivo: archivo,
            transferible:
              metadata.customMetadata && metadata.customMetadata.transferible
                ? metadata.customMetadata.transferible
                : true,
          });
        } catch (error) {
          //alert("Error al cambiar la transferibilidad: " + error);
        }
      }
    }
    setArchivosTraferibles(arrayDatetes);
  };
  function extraerAntesUltimaBarra(cadena) {
    // Buscar la posición de la última barra en la cadena
    const ultimaBarraIndex = cadena.lastIndexOf("/");

    // Verificar si se encontró la barra
    if (ultimaBarraIndex !== -1) {
      // Extraer la parte de la cadena antes de la última barra, incluida la barra misma
      const parteAntesUltimaBarra = cadena.substring(0, ultimaBarraIndex + 1);
      return parteAntesUltimaBarra;
    } else {
      // Si no se encontró ninguna barra, retornar la cadena original
      return cadena;
    }
  }
  const [rolesEnvios, setRolesEnvios] = useState([]);

  const obtenerSoloPresi = async (path) => {
    //const path = '11111111A/01 Información Contable/eltroglotida';

    try {
      const soloPresiRef = await get(
        firebase.getReferencia("SoloPresi/" + path)
      );
      const soloPresi = [];
      soloPresiRef.forEach((childSnapshot) => {
        const childData = childSnapshot.val();
        soloPresi.push(childData);
      });
      setRolesEnvios(soloPresi);
    } catch (error) {
      console.error("Error obteniendo datos", error);
    }
  };

  useEffect(async () => {
    await obtenerNombreComunidad();
    await cargarRoles();
    await loadDocuments();
    await cargarSoloPresi();

    // Si tiene idJunta Navego a junta la primera vez que entro, luego que se comporte como sea
    if (IdJunta) {
      const TituloJunta = await getTituloJunta(
        firebase,
        Identificador,
        IdJunta
      );
      const carpetaJunta = `${Identificador}/${nombreJuntasPropietarios}/${TituloJunta}`;
      const carpeta = carpetaJunta.split("/").pop();
      const carpetaActual = await firebase.getFolderContents(carpetaJunta);
      setArchivosEnCarpetaArborist({
        carpeta: carpeta,
        archivos: carpetaActual.map((item) => item.name),
        path: carpetaJunta,
        firstLevel: false,
      });

      setSeleccionNuevaCarpeta(carpetaJunta);
    }
  }, [refrescar]);

  useEffect(async () => {
    ObtenerArchivosTransferibles();
  }, [archivosEnCarpetaArborist]);

  useEffect(async () => {
    cargarSoloPresi();
  }, [refrescarSoloPresi]);

  const fileTypes = getSupportedFileTypes();
  const uploadLimits = getUploadLimits();
  const maxArchivos = uploadLimits.maxArchivos;
  const maxFileSizeMB = uploadLimits.maxFileSizeMB;

  const handleDrop = async (e) => {
    e.preventDefault();

    try {
      const files = e.dataTransfer.files;

      if (files.length > maxArchivos) {
        throw diccionario.MaximoArchivos.replace("${var1}", maxArchivos);
      }

      const uploadPromises = [];
      let totalSize = 0;

      let archivos = [];

      for (const file of files) {
        // Check file type
        if (!fileTypes.includes(file.type)) {
          throw diccionario.TipoArchivoNoPermitido.replace(
            "${var1}",
            file.type
          );
        }

        // Check file size
        if (file.size > maxFileSizeMB * 1024 * 1024) {
          throw diccionario.ambioEstadoIncidenciaCodigo
            .replace("${var1}", file.type)
            .replace("${var2}", maxFileSizeMB);
        }

        totalSize += file.size;

        const fileRef = ref(
          firebase.storage,
          `${archivosEnCarpetaArborist.path}/${file.name}`
        );

        const uploadPromise = uploadBytes(fileRef, file).then((snapshot) => {});
        archivos.push(file.name);
        uploadPromises.push(uploadPromise);
      }

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);

      setSeleccionNuevaCarpeta(archivosEnCarpetaArborist.path);
      await refrescarCarpetaNueva();
      const carpetaActual = await firebase.getFolderContents(
        archivosEnCarpetaArborist.path
      );
      const carpeta =
        archivosEnCarpetaArborist.path !== Identificador + "/"
          ? archivosEnCarpetaArborist.path.split("/").pop()
          : archivosEnCarpetaArborist.path + "/";
      if (docusUsuario !== "true") {
        setArchivosEnCarpetaArborist({
          carpeta: carpeta,
          archivos: carpetaActual.map((item) => item.name),
          path: archivosEnCarpetaArborist.path,
          firstLevel:
            archivosEnCarpetaArborist.path === Identificador + "/"
              ? true
              : false,
        });
      }
      setLoading(false);
      toast.success(
        diccionario.ArchivosSubidosCorrectamente,
        getCustomToastOptions(toast)
      );
    } catch (error) {
      toast.error(error, getCustomToastOptions(toast, "error"));
    }
    setEstaArrastrandoEncima(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleCarpeta = () => {
    setMostrarInput(true);
  };

  const handleChange = (event) => {
    setNombreCarpeta(event.target.value);
  };
  const modalEnvio = () => {
    setModalOpen(true);
  };

  const crearCosa = async () => {
    if (nombreCarpeta === "") {
      toast.error(
        diccionario.NombreCarpetaVacio,
        getCustomToastOptions(toast, "error")
      );
      return;
    }

    if (archivosEnCarpetaArborist.archivos.includes(nombreCarpeta)) {
      toast.error(
        diccionario.MismoNombreArchivoMismoNivel,
        getCustomToastOptions(toast, "error")
      );
      return;
    }
    try {
      const nombreFinal = nombreCarpeta.trim();
      const spaceRef = ref(
        firebase.storage,
        archivosEnCarpetaArborist.path + "/" + nombreFinal + "/temporal.txt"
      );

      const message = "mensajesublemuinal";

      uploadString(spaceRef, message).then(async (snapshot) => {
        setMostrarInput(false);
        setNombreCarpeta("");
        await refrescarCarpetaNueva();
        setArchivosEnCarpetaArborist({
          carpeta: nombreFinal,
          path: archivosEnCarpetaArborist.path + "/" + nombreFinal,
          archivos: [],
        });
        let rutaCarpetanueva =
          archivosEnCarpetaArborist.path + "/" + nombreFinal;
        rutaCarpetanueva = rutaCarpetanueva.replace(/\/\/+/g, "/");

        setSeleccionNuevaCarpeta(rutaCarpetanueva);
        toast.success(
          diccionario.CarpetaCreadaCorrectamente,
          getCustomToastOptions(toast)
        );
      });
    } catch (error) {
      toast.error(
        diccionario.ErrorCrearCarpeta,
        getCustomToastOptions(toast, "error")
      );
    }
  };

  const asignarSoloPresi = async (e, pathCarpeta) => {
    /*
    if (archivosEnCarpetaArborist.firstLevel === true) {
      pathCarpeta = Identificador+'/'+ pathCarpeta;
    }
*/

    const opts = [];
    e.map((opcion) => {
      opts.push(opcion.label);
    });
    const soloPresiUp = firebase.getReferencia(
      `SoloPresi/${pathCarpeta}`.toString(),
      null
    );
    await set(soloPresiUp, opts);
    toast.success(
      diccionario.DatosGuardadosCorrectamente,
      getCustomToastOptions(toast)
    );
    cargarSoloPresi();
    setRefrescarRoles(!refrescarRoles);
  };
  const calcularMoverSoloPresi = async (pathCarpeta, pathCarpetaDestino) => {
    const contenidosCarpeta = await firebase.getFolderContents(pathCarpeta);
    let foldersCarpeta = extractPaths(contenidosCarpeta);
    foldersCarpeta.unshift(pathCarpeta);
    const foldersDestino = transformPaths(
      foldersCarpeta,
      pathCarpeta,
      pathCarpetaDestino
    );
    return {
      origin: foldersCarpeta,
      destiny: foldersDestino,
    };
  };

  const onMove = async ({ dragIds, parentId, index }) => {
    // No permito mover carpetas protegidas....
    if (
      dragIds[0] === nombreDocumentacionGeneral ||
      dragIds[0] === nombreJuntasPropietarios
    ) {
      toast.error(
        diccionario.ErrorMoverCarpetaNoPermitido,
        getCustomToastOptions(toast, "error")
      );
      return 0;
    }

    setLoading(true);
    const movedNode = findNodeById(gData, dragIds[0]);
    // FALTA COMPROBAR SI EXISTE DESTINO. SI EXISTE DESTINO NO DEJAR MOVER
    const moveFile = httpsCallable(functions, "moveFile");
    parentId = parentId === null ? "./" : parentId;

    if (parentId === "./") {
      parentId = Identificador;
    }

    if (docusUsuario === "true" && parentId === Identificador) {
      parentId = `usuarios/${Identificador}`;
    }

    moveFile({
      origin: dragIds[0],
      destination: parentId,
      bucket: firebase.storage._bucket.bucket,
      type: movedNode.isFile === true ? "file" : "folder",
    })
      .then(async (res) => {
        if (!movedNode.isFile && dragIds && parentId) {
          const pathOrigen = dragIds[0];
          const pathDestino = parentId;

          let partes = pathOrigen.split("/");
          partes.shift();
          //let rolesOrigen = soloPresi;
          let rolesOrigen =
            archivosEnCarpetaArborist.path.includes(
              nombreDocumentacionGeneral
            ) && Identificador !== nombreDocumentacionGeneral
              ? soloPresi[nombreDocumentacionGeneral]
              : soloPresi;
          let last = "";
          partes.map((parte) => {
            rolesOrigen = rolesOrigen
              ? rolesOrigen[parte.toString()]
              : rolesOrigen;
            last = parte;
          });

          const soloPresiUp = firebase.getReferencia(
            `SoloPresi/${pathDestino}/${last}`.toString(),
            null
          );
          if (Array.isArray(rolesOrigen)) {
            await set(soloPresiUp, rolesOrigen);
          }
          const refDelete = firebase.getReferencia(
            `SoloPresi/${pathOrigen}`.toString()
          );
          await remove(refDelete);
        }
        refresh();
        setSeleccionNuevaCarpeta(parentId);
        //await refrescarCarpetaNueva();
        let carpetaActual = await firebase.getFolderContents(parentId);
        const carpeta =
          archivosEnCarpetaArborist.path !== Identificador + "/"
            ? parentId.split("/").pop()
            : parentId + "/";

        // Añado la carpeta 10 Documentacion General si no estamos en la documentación general desde el despacho y estamos en raíz
        if (
          Identificador !== nombreDocumentacionGeneral &&
          docusUsuario !== "true" && parentId === Identificador
        ) {
          let docuGeneral = await firebase.getFolderContents("");
          docuGeneral = docuGeneral.filter(
            (carpeta) => carpeta.id === nombreDocumentacionGeneral
          );
          carpetaActual = carpetaActual.concat(docuGeneral);
        }

        setArchivosEnCarpetaArborist({
          carpeta: carpeta,
          archivos: carpetaActual.map((item) => item.name),
          path: parentId,
          firstLevel: parentId === Identificador + "/" ? true : false,
        });
        toast.success(
          diccionario.CarpetaMovidaCorrectamente,
          getCustomToastOptions(toast)
        );
      })
      .catch((e) => {
        console.log("error", e);
        toast.error(
          diccionario.ErrorMoverCarpeta,
          getCustomToastOptions(toast, "error")
        );
      });
  };

  if (loading) {
    return (
      <div>
        <ClipLoader
          size={100}
          color={"#123abc"}
          loading={loading}
          cssOverride={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          speedMultiplier={1}
        />
      </div>
    );
  }

  const handleModificar = async (e) => {
    if (esGeneral) {
      const carpetaAnterior = archivosEnCarpetaArborist.path
        .split("/")
        .slice(0, -1)
        .join("/");
      const comprobarCarpetaAnterior = await firebase.getFolderContents(
        carpetaAnterior
      );

      if (comprobarCarpetaAnterior.some((item) => item.name === inputValue)) {
        toast.error(
          diccionario.MismoNombreArchivoMismoNivel,
          getCustomToastOptions(toast, "error")
        );
        return 0;
      }
    }

    if (
      pathCarpetaModificar ===
      Identificador + "/" + nombreJuntasPropietarios
    ) {
      toast.error(
        diccionario.Carpeta03NoBorrar,
        getCustomToastOptions(toast, "error")
      );
      return 0;
    }

    if (!nombreCarpetaModificar.includes(".")) {
      if (archivosEnCarpetaArborist.archivos.includes(inputValue)) {
        toast.error(
          diccionario.MismoNombreArchivoMismoNivel,
          getCustomToastOptions(toast, "error")
        );
        return;
      }
    }

    const transferibles = archivosTraferibles
      .filter((archivo) => archivo.archivo === nombreCarpetaModificar)
      .map((archivo) => archivo.transferible);

    if (checkedTransferible == false) {
      if (
        transferibles == "false" &&
        nombreCarpetaModificar.split(".")[0] == inputValue
      ) {
        toast.error(
          diccionario.GuardarCambiosModificar,
          getCustomToastOptions(toast, "error")
        );
        return;
      }
      const forestRef = ref(firebase.storage, pathCarpetaModificar);

      const newMetadata = {
        customMetadata: {
          transferible: "false", // Debes definir metadatos personalizados dentro de `customMetadata`
        },
      };
      updateMetadata(forestRef, newMetadata)
        .then((metadata) => {
          //console.log("metadata", metadata);
        })
        .catch((error) => {
          //alert("Error al cambiar la transferibilidad" + error);
        });
    }
    if (checkedTransferible == true) {
      if (
        transferibles == "true" &&
        nombreCarpetaModificar.split(".")[0] == inputValue
      ) {
        toast.error(
          diccionario.GuardarCambiosModificar,
          getCustomToastOptions(toast, "error")
        );
        return;
      }
      const forestRef = ref(firebase.storage, pathCarpetaModificar);

      const newMetadata = {
        customMetadata: {
          transferible: "true", // Debes definir metadatos personalizados dentro de `customMetadata`
        },
      };
      updateMetadata(forestRef, newMetadata)
        .then((metadata) => {
          //console.log("metadata", metadata);
        })
        .catch((error) => {
          alert(diccionario.ErrorCambiarTransferibilidad + error);
        });
    }

    const partes = pathCarpetaModificar.split("/");
    const resultado = partes.slice(0, -1).join("/");
    const nombreRepetido = await firebase.getFolderContents(resultado);
    const nombres = nombreRepetido.map((item) => item.name);

    let nombreOrigen = pathCarpetaModificar;

    const temporalOrigen = nombreOrigen;
    let archivosEnCarpeta = esGeneral
      ? archivosEnCarpetaArborist.path.split("/").slice(0, -1).join("/")
      : archivosEnCarpetaArborist.path;

    let nombreDestino = archivosEnCarpeta + "/" + inputValue;
    nombreDestino = nombreDestino.replace(/\/\/+/g, "/");

    if (nombreOrigen.includes(".")) {
      const extension = nombreOrigen.split(".").pop();
      nombreDestino = nombreDestino + "." + extension;
    }

    setLoading(true);
    const movedNode = findNodeById(gData, nombreOrigen);

    const moveFile = httpsCallable(functions, "moveFile");

    nombreDestino = nombreDestino === null ? "./" : nombreDestino;

    const temporalDestino = nombreDestino;

    moveFile({
      origin: nombreOrigen,
      destination: nombreDestino,
      bucket: firebase.storage._bucket.bucket,
      type: "renameFolder",
    })
      .then(async (res) => {
        let carpetaActual = await firebase.getFolderContents(
          esGeneral
            ? nombreDestino
            : nombreDestino.substring(0, nombreDestino.lastIndexOf("/"))
        );
        const carpeta =
          nombreDestino !== Identificador + "/"
            ? nombreDestino.split("/").pop()
            : nombreDestino + "/";

        nombreDestino = esGeneral
          ? nombreDestino
          : nombreDestino.substring(0, nombreDestino.lastIndexOf("/"));
        let treeElements = await firebase.getFolderContents(rootFolder);

        // Añado la carpeta 10 Documentacion General si no estamos en docus usuario
        const concatenato = [];
        if (docusUsuario !== "true") {
          let docuGeneral = await firebase.getFolderContents("");
          docuGeneral = docuGeneral.filter(
            (carpeta) => carpeta.id === nombreDocumentacionGeneral
          );
          treeElements = treeElements.concat(docuGeneral);
          //carpetaActual.push(docuGeneral);
          carpetaActual = carpetaActual.concat(docuGeneral);
        }
        
        /*
        treeElements.map(rama => {
          if(rama.id===nombreDocumentacionGeneral){
            rama.nameUPD = "Documentación general";
          }
        });
        */
        setGData(treeElements);

        setArchivosEnCarpetaArborist({
          carpeta: carpeta,
          archivos: carpetaActual.map((item) => item.name),
          path: nombreDestino,
          firstLevel: nombreDestino === Identificador + "/" ? true : false,
        });

        toast.success(
          diccionario.NombreCambiadoCorrectamente,
          getCustomToastOptions(toast)
        );
        setShowDialogModificar(false);
        setLoading(false);

        setInputValue("");

        setSeleccionNuevaCarpeta(nombreDestino);
        /*
        let gDataModificado = renameNodeById(gData, nombreOrigen, nombreDestino);
        
        setGData(gDataModificado);
 */
        //setRefrescar(!refrescar);

        setEsGeneral(false);

        if (
          !temporalOrigen.includes(".") &&
          temporalOrigen &&
          temporalDestino
        ) {
          const pathOrigen = temporalOrigen;
          const pathDestino = temporalDestino;

          let partes = pathOrigen.split("/");
          partes.shift();
          let rolesOrigen = soloPresi;
          partes.map((parte) => {
            rolesOrigen = rolesOrigen
              ? rolesOrigen[parte.toString()]
              : rolesOrigen;
          });
          const soloPresiUp = firebase.getReferencia(
            `SoloPresi/${pathDestino}`.toString(),
            null
          );
          if (Array.isArray(rolesOrigen)) {
            await set(soloPresiUp, rolesOrigen);
          }
          const refDelete = firebase.getReferencia(
            `SoloPresi/${pathOrigen}`.toString()
          );
          await remove(refDelete);
          setRefrescarSoloPresi(!refrescarSoloPresi);
        }
      })
      .catch((e) => {
        toast.error(
          diccionario.ErrorCambiarNombre,
          getCustomToastOptions(toast, "error")
        );
        console.log("error", e);
      });
  };

  const handleMostrarDialogo = (
    callbackFunction,
    pathBorrar,
    mensaje,
    input,
    e,
    nombreCambiar
  ) => {
    setDialogoContenido({
      titulo: mensaje.titulo,
      cuerpo: mensaje.cuperpomensaje,
      path: pathBorrar,
      ejecutarFuncion: () => callbackFunction(pathBorrar, e),
      inputElement: input,
      nombreCambiar: nombreCambiar,
    });

    setShowDialog(true);
  };

  const handleMostrarDialogoCambiar = async (pathOrigen, nombreCambiar) => {
    const transferibles = archivosTraferibles
      .filter((archivo) => archivo.archivo === nombreCambiar)
      .map((archivo) => archivo.transferible);

    await trapaceria(transferibles);

    setInputValue(nombreCambiar.split(".")[0]);
    setNombreCarpetaModificar(nombreCambiar);
    setPathCarpetaModificar(pathOrigen);
    abrirDialogoModificar();
  };

  const trapaceria = async (cosagorda) => {
    if (cosagorda == "true") {
      setCheckedTransferible(true);
    }

    if (cosagorda == "false") {
      setCheckedTransferible(false);
    }
  };
  const abrirDialogoModificar = () => {
    setShowDialogModificar(true);
  };

  const removeLastPartOfPath = (input) => {
    // Find the last occurrence of '/'
    const lastSlashIndex = input.lastIndexOf('/');
    
    // Return everything before the last '/'
    if (lastSlashIndex !== -1) {
      return input.substring(0, lastSlashIndex);
    } else {
      // If there's no '/' in the input, return the input as is
      return input;
    }
  }

  const handleBorrarCarpeta = async (path) => {
    if (path === Identificador + "/" + nombreJuntasPropietarios) {
      toast.error(
        diccionario.Carpeta03NoBorrar,
        getCustomToastOptions(toast, "error")
      );
      setShowDialog(false);
      return;
    }

    try {
      await borrarCarpeta(path);
      await loadDocuments();
      await navegarPath(removeLastPartOfPath(path));
      //refresh();
      setShowDialog(false);
    } catch (error) {
      console.error("Error al borrar el archivo/carpeta", error);
    }
  };

  const borrarCarpeta = async (path) => {
    try {
      let refGeneral = ref(firebase.storage, path);
      const items = await listAll(refGeneral);

      const deleteFilePromises = items.items.map(async (fileRef) => {
        let refDel = ref(firebase.storage, fileRef._location.path);

        await deleteObject(refDel);
      });

      const deleteSubfolderPromises = items.prefixes.map(
        async (subfolderRef) => {
          const subfolderPath = subfolderRef._location.path;
          return borrarCarpeta(subfolderPath);
        }
      );

      // Wait for all file and subfolder deletions to complete
      await Promise.all([...deleteFilePromises, ...deleteSubfolderPromises]);
      // Borrado de roles en soloPresi
      const refDelete = firebase.getReferencia(`SoloPresi/${path}`.toString());
      await remove(refDelete);
    } catch (error) {
      console.error("Error al borrar el archivo/carpeta", error);
    }
  };

  const getPathWithoutFile = (input) => {
    // Find the last occurrence of '/'
    const lastSlashIndex = input.lastIndexOf('/');
    
    // Return everything before the last '/'
    return input.substring(0, lastSlashIndex);
  }

  const handleBorrarFichero = async (archivo) => {
    const desertRef = ref(firebase.storage, archivo);
    
    try {
      await deleteFileOrFolder(desertRef, archivo);
      await loadDocuments();
      await navegarPath(getPathWithoutFile(archivo));
      //refresh();
      setShowDialog(false);
    } catch (error) {
      console.error("Error al borrar el archivo/carpeta", error);
    }
  };

  const deleteFileOrFolder = async (ref, name) => {
    try {
      await deleteObject(ref);
      /*
      setArchivosEnCarpetaArborist((prevArchivos) => ({
        carpeta: prevArchivos.carpeta,
        archivos: prevArchivos.archivos.filter((a) => a !== name),
        path: prevArchivos.path,
      }));
      */
    } catch (error) {
      console.error("Error al borrar el archivo/carpeta: " + name, error);
    }
  };

  const handleClick = () => {
    // Crear elemento de entrada de archivos

    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;

    const uploadPromises = [];
    // Adjuntar escucha de eventos para la selección de archivos
    input.addEventListener("change", async (event) => {
      const files = event.target.files;

      const uploadPromises = [];
      if (files.length > 0) {
        try {
          const uploadPromises = [];
          let totalSize = 0;

          for (const file of files) {
            if (!fileTypes.includes(file.type)) {
              console.error(`Tipo de archivo no permitido: ${file.type}`);
              throw diccionario.TipoArchivoNoPermitido;
            }
            if (file.size > maxFileSizeMB * 1024 * 1024) {
              throw diccionario.ArchivoTamañoMaximoSuperado.replace(
                "${var1}",
                file.type
              ).replace("${var2}", maxFileSizeMB);
            }
            if (files.length > maxArchivos) {
              throw diccionario.MaximoArchivos.replace("${var1}", maxArchivos);
            }

            totalSize += file.size;

            const fileRef = ref(
              firebase.storage,
              `${archivosEnCarpetaArborist.path}/${file.name}`
            );

            const uploadPromise = uploadBytes(fileRef, file).then(
              (snapshot) => {}
            );
            uploadPromises.push(uploadPromise);

            totalSize += file.size;
          }

          await Promise.all(uploadPromises);
          await refrescarCarpetaNueva();
          const carpetaActual = await firebase.getFolderContents(
            archivosEnCarpetaArborist.path
          );
          const carpeta = archivosEnCarpetaArborist.path.split("/").pop();
          if (docusUsuario !== "true") {
            setArchivosEnCarpetaArborist({
              carpeta: carpeta,
              archivos: carpetaActual.map((item) => item.name),
              path: archivosEnCarpetaArborist.path,
              firstLevel:
                archivosEnCarpetaArborist.path === Identificador + "/"
                  ? true
                  : false,
            });
          }

          setLoading(false);
          toast.success(
            diccionario.ArchivosSubidosCorrectamente,
            getCustomToastOptions(toast)
          );
        } catch (error) {
          toast.error(error, getCustomToastOptions(toast, "error"));
          console.error("Error al cargar los archivos:", error);
        }
      }
    });

    // Activar el cuadro de diálogo de archivos
    input.click();
  };
  const validarCaracteresCarpeta = (event) => {
    // Disallow "/" and "." characters
    if (event.key === "/" || event.key === ".") {
      event.preventDefault();
    }
  };

  // Definimos el estado inicial como true
  const nombreEspecial =
  docusUsuario === "true" ? "Mi Carpeta" : Identificador !== "10 Documentación general" ? "Raíz" :diccionario.DocumentacionGeneral;

  const validarCaracteresCarpetaPaste = (event) => {
    // Disallow "/" and "." characters for pasting
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    if (pastedText.includes("/") || pastedText.includes(".")) {
      event.preventDefault();
    }
  };

  const validarCaracteresCarpetaVacio = (event) => {
    // Prevent setting the value to only a space character
    if (event.target.value.trim() === "") {
      event.preventDefault();
    }
  };
  const navegarPath = async (path) => {
    //const nodoSeleccionado = findNodeById(gData,path);
    const carpeta = path.split("/").pop();
    let carpetaActual = await firebase.getFolderContents(path);

    // Si estamos navegando a raíz y no estamos en documentos de usuario añado artificialmente la carpeta de documentación general

    if (path === Identificador && docusUsuario !== "true" && Identificador !== nombreDocumentacionGeneral){

      let docuGeneral = await firebase.getFolderContents("");
      docuGeneral = docuGeneral.filter(
        (carpeta) => carpeta.id === nombreDocumentacionGeneral
      );
      carpetaActual = carpetaActual.concat(docuGeneral);
    }

    setArchivosEnCarpetaArborist({
      carpeta: carpeta,
      archivos: carpetaActual.map((item) => item.name),
      path: path,
      firstLevel: path === Identificador + "/" ? true : false,
    });

    setSeleccionNuevaCarpeta(path);
  };
  const RutaEnlaces = ({ ruta }) => {
    // Dividir la cadena de ruta en partes usando "/"
    let partes = ruta.split("/").filter((part) => part.trim() !== "");
    //partes = partes.filter(part => part !== 'usuarios'); //
    let part = "";
    return (
      <div className="path-clickable">
        {partes.map((nombre, index) => {
          if (!(docusUsuario === "true" && index === 0)) {
            let part = partes.slice(0, index + 1).join("/");

            const nombreEspecial =
              docusUsuario === "true" ? "Mi Carpeta" : Identificador !== "10 Documentación general" ? "Raíz" :diccionario.DocumentacionGeneral;
            return (
              <React.Fragment key={index}>
                <a onClick={() => navegarPath(part)}>
                  {nombre === Identificador
                    ? nombreEspecial
                    : nombre === "10 Documentación general"
                    ? "Documentación general"
                    : nombre}
                </a>
                {index < partes.length - 1 && <span>&gt;</span>}
              </React.Fragment>
            );
          }
        })}
      </div>
    );
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const Cursor = ({ top, left }) => {
    return React.createElement("div", {
      className: "barrabaja",
      style: { top, left },
    });
  };

  const toggleChecked = () => {
    setCheckedTransferible(!checkedTransferible);
    setDeshabilidato(false); // Cambiamos el valor de true a false y viceversa
  };

  const dentroDeDocuGeneral = (path) => {
    const prefix = "10 Documentación general";
    return path.startsWith(prefix);
  }

  return (
    <div className="container-de-los-documentos">
      <div className="contenedor-ficheros-principal">
        <Dialogo
          inputValue={inputValue}
          setInputValue={setInputValue}
          inputElement={dialogoContenido.inputElement ? true : false}
          showDialog={showDialog}
          diagoloBody={dialogoContenido.cuerpo}
          diagoloTitulo={dialogoContenido.titulo}
          onConfirm={dialogoContenido.ejecutarFuncion}
          nombreCambiar={dialogoContenido.nombreCambiar}
          onClose={() => setShowDialog(false)}
        />

        <Modal
          show={showDialogModificar}
          onHide={handleCloseModificar}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {diccionario.CambiarNombreDe}
              {nombreCarpetaModificar}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{diccionario.AnadirNombreCarpetaFichero}</Modal.Body>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignContent: "center",
              marginLeft: "100px",
              marginRight: "100px",
              marginBottom: "20px",
            }}
          >
            <form>
              <TextField
                value={inputValue} // Usa el valor del estado local
                onChange={handleInputChange}
                name="texto_"
                required
                inputProps={{
                  onKeyPress: validarCaracteresCarpeta,
                  onPaste: validarCaracteresCarpetaPaste,
                  maxLength: 35,
                  minLenght: 1,
                }}
                className="buscador"
                autoFocus
              />
              {nombreCarpetaModificar.includes(".") ? (
                <div
                  className="traspasble"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: "0px",
                    marginTop: "10px",
                  }}
                >
                  <Tooltip content={"Este documento pertenece a la comunidad"}>
                    {" "}
                    <IoInformationCircleOutline
                      color="blue"
                      style={{
                        fontSize: "16px",
                        marginRight: "2px",
                        marginBottom: "2px",
                      }}
                    />
                  </Tooltip>

                  <p style={{ margin: "0px", marginRight: "10px" }}>
                    {" "}
                    Es transferible
                  </p>
                  <div></div>
                  <Switch
                    checked={checkedTransferible}
                    onChange={toggleChecked}
                    onColor="#86d3ff"
                    onHandleColor="#2693e6"
                    handleDiameter={10}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={15}
                    width={28}
                  />
                  <p>
                    {" "}
                    {checkedTransferible ? diccionario.Si : diccionario.No}
                  </p>
                </div>
              ) : null}
            </form>
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModificar}>
              {diccionario.cancelar}
            </Button>
            <Button
              variant="primary"
              onClick={(e) => handleModificar(e)}
              disabled={inputValue.length < 1}
            >
              {diccionario.aceptar}
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  WORK IN PROGRESSS 

<Modal show={showDialogSubida} onHide={handleCloseModificar} centered>
      <Modal.Header closeButton>
        <Modal.Title>Subida de archivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Indica si este documento es propiedad de la comunidad, si es así, quedará en el repositorio de la comunidad y podrá ser compartido, por el contrario, si este documento es privado y no puede quedar en el repositorio de la comunidad, indícalo.
        <div>
          <label>
            <input
              type="radio"
              value="si"
              checked={respuesta === "si"}
              onChange={handleChangeRespuesta}
            />
            Sí
          </label>
          <label>
            <input
              type="radio"
              value="no"
              checked={respuesta === "no"}
              onChange={handleChangeRespuesta}
            />
            No
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModificar}>
          {diccionario.cancelar}
        </Button>
        <Button variant="primary" onClick={handleClick}>
          {diccionario.aceptar}
     
        </Button>
      </Modal.Footer>
    </Modal>

*/}

        <div className="contenedor-ficheros">
          {docusUsuario !== "true" && (
            <div
              className="nombreComu"
              style={{ marginTop: "10px", marginBottom: "25px" }}
            >
              <p
                style={{ margin: "0px", fontWeight: "bold", fontSize: "14px" }}
              >
                {" "}
                {Identificador === "documentaciongeneral"
                  ? diccionario.DocumentacionGeneral
                  : diccionario.DocumentosComunidad}
              </p>
              <span
                style={{ color: "blue", fontWeight: "400", fontSize: "16px" }}
              >
                {" "}
                {nombreComu}
              </span>
            </div>
          )}
          <input
            type="text"
            placeholder={diccionario.Busqueda}
            className="search-input"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          />
          <Tree
            renderCursor={Cursor}
            ref={treeRef}
            data={gData}
            width={280}
            height={540}
            onMove={onMove}
            onDrop={handleDrop}
            indent={30}
            rowHeight={32}
            searchTerm={term}
            openByDefault={false}
            selection={seleccionNuevaCarpeta ? seleccionNuevaCarpeta : null}
            searchMatch={(node, term) =>
              node.data.name.toLowerCase().includes(term.toLowerCase())
            }
            childrenAccessor={(d) => d.folders}
          >
            {(props) => (
              <Node
                {...props}
                setArchivosEnCarpetaArborist={setArchivosEnCarpetaArborist}
                setSeleccionNuevaCarpeta={setSeleccionNuevaCarpeta}
                refrescarCarpetaNueva={refrescarCarpetaNueva}
                Identificador={Identificador}
                refrescar={refrescar}
                setRefrescar={setRefrescar}
                refresh={refresh}
                setLoading={setLoading}
              />
            )}
          </Tree>
        </div>
        {archivosEnCarpetaArborist.carpeta.length > 0 ? (
          <div
            className="contenedor-detalle-ficheros"
            onDrop={handleDrop}
            onDragOver={manejarArrastreEncima}
            onDragLeave={manejarArrastreFuera}
            style={{
              border: estaArrastrandoEncima
                ? "2px solid black"
                : "2px solid white",
              backgroundColor: estaArrastrandoEncima ? "#496af826" : "white",
              transition: "border 0.5s ease, background-color 0.5s ease",
            }}
          >
            <div className="container mt-4">
              <div className="aviso-fichero">
                <h4>
                  <RutaEnlaces ruta={archivosEnCarpetaArborist.path} />
                </h4>
              </div>
              <div>
                {!mostrarInput ? (
                  <div style={{ marginTop: "20px" }}>
                    <p style={{ fontWeight: "bold" }}>
                      {diccionario.CarpetaActual} &nbsp;
                      {(archivosEnCarpetaArborist.carpeta &&
                        archivosEnCarpetaArborist.carpeta == Identificador) ||
                      archivosEnCarpetaArborist.carpeta == Identificador + "/"
                        ? nombreEspecial
                        : archivosEnCarpetaArborist.carpeta ===
                          "10 Documentación general"
                        ? "Documentación general"
                        : archivosEnCarpetaArborist.carpeta}
                    </p>
                    <h5>
                      <button
                        type="button"
                        class="btn btn-outline-dark btn-sm mx-2"
                        /* disabled={
                          !archivosEnCarpetaArborist.archivos.some((element) =>
                            element.includes(".")
                          )
                        }*/
                        onClick={async () => {
                          await obtenerSoloPresi(
                            archivosEnCarpetaArborist.path
                          );
                          setPathAvisos(archivosEnCarpetaArborist.path);
                          modalEnvio();
                        }}
                      >
                        Enviar aviso
                      </button>
                      <FaFolderPlus
                        cursor={"pointer"}
                        color="orange"
                        style={{ fontSize: "22px" }}
                        onClick={handleCarpeta}
                      />
                      {!(docusUsuario && archivosEnCarpetaArborist.path.replace(/\/+$/, '')===`usuarios/${Identificador}`) && archivosEnCarpetaArborist.path !== Identificador + "/" &&
                      archivosEnCarpetaArborist.path !== Identificador ? (
                        <>
                          {archivosEnCarpetaArborist.path !==
                            nombreDocumentacionGeneral &&
                            archivosEnCarpetaArborist.path !==
                              Identificador +
                                "/" +
                                nombreJuntasPropietarios && (
                              <FaFolderMinus
                                cursor={"pointer"}
                                color="red"
                                style={{ fontSize: "22px", marginLeft: "10px" }}
                                onClick={() =>
                                  handleMostrarDialogo(
                                    handleBorrarCarpeta,
                                    archivosEnCarpetaArborist.path,
                                    {
                                      titulo:
                                        diccionario.tituloConfirmarBorradoCarpeta +
                                        archivosEnCarpetaArborist.carpeta,
                                      cuperpomensaje:
                                        diccionario.textoConfirmarBorradoCarpeta,
                                    }
                                  )
                                }
                              />
                            )}
                          {archivosEnCarpetaArborist.path !==
                            nombreDocumentacionGeneral &&
                            archivosEnCarpetaArborist.path !==
                              Identificador +
                                "/" +
                                nombreJuntasPropietarios && (
                              <LuFolderEdit
                                cursor={"pointer"}
                                color="green"
                                style={{ fontSize: "22px", marginLeft: "10px" }}
                                onClick={() => {
                                  handleMostrarDialogoCambiar(
                                    archivosEnCarpetaArborist.path,
                                    archivosEnCarpetaArborist.carpeta
                                  );
                                  setEsGeneral(true);
                                }}
                              />
                            )}
                        </>
                      ) : null}
                    </h5>
                  </div>
                ) : (
                  <div style={{ width: "340px", display: "flex" }}>
                    <TextField
                      value={nombreCarpeta}
                      onChange={handleChange}
                      placeholder={diccionario.NombreCarpeta}
                      type="text"
                      name="texto_"
                      required
                      inputProps={{
                        onKeyPress: validarCaracteresCarpeta,
                        onPaste: validarCaracteresCarpetaPaste,
                        maxLength: 35,
                        minLenght: 10,
                      }}
                      className="buscador"
                      autoFocus
                    />
                    <button
                      disabled={
                        (!nombreCarpeta || nombreCarpeta.trim() === "") && true
                      }
                      className="btn btn-outline-primary "
                      onClick={crearCosa}
                      style={{ marginLeft: "10px" }}
                    >
                      Guardar
                    </button>
                    <button
                      className="btn btn-outline-secondary "
                      onClick={() => {
                        setMostrarInput(false);
                        setNombreCarpeta("");
                      }}
                      style={{ marginLeft: "10px" }}
                    >
                      Cancelar
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <ul className="list-group">
                {archivosEnCarpetaArborist.archivos.map((archivo, index) => {
                  const extension = archivo.includes(".")
                    ? archivo.split(".").pop().toLowerCase()
                    : "";
                  const icono = obtenerIconoPorExtension(extension);

                  let pathCarpeta = "";
                  if (archivo === nombreDocumentacionGeneral) {
                    pathCarpeta = archivo;
                  } else if (docusUsuario != "true") {
                    pathCarpeta =
                      archivosEnCarpetaArborist.firstLevel !== true
                        ? archivosEnCarpetaArborist.path + "/" + archivo
                        : Identificador + "/" + archivo;
                  } else {
                    pathCarpeta =
                      archivosEnCarpetaArborist.firstLevel !== true
                        ? archivosEnCarpetaArborist.path + "/" + archivo
                        : "usuarios/" + Identificador + "/" + archivo;
                  }
                  let rolesAsignados = [];

                  const pathSinComu = pathCarpeta.replace(/^[^/]*\//, "");

                  const partes = pathSinComu.split("/");

                  // Si estamos dentro de Documentacion general cambiamos de dónde se saca el dato dentro de soloPresi
                  let trozaco =
                    archivosEnCarpetaArborist.path.includes(
                      nombreDocumentacionGeneral
                    ) && Identificador !== nombreDocumentacionGeneral
                      ? soloPresi[nombreDocumentacionGeneral]
                      : soloPresi;

                  partes.map((parte) => {
                    trozaco = trozaco ? trozaco[parte.toString()] : trozaco;
                  });
                  const result = [];
                  for (const key in trozaco) {
                    if (!Array.isArray(trozaco[key])) {
                      result[key] = trozaco[key];
                    }
                  }

                  rolesAsignados = Array.isArray(result) ? result : [];

                  let rolesTuneados = [];
                  rolesAsignados.map((valor) => {
                    rolesTuneados.push({
                      label: valor,
                      value: valor,
                      selected: true,
                    });
                  });
                  rolesAsignados = rolesTuneados;

                  const nombreElemento =
                    archivosEnCarpetaArborist.firstLevel !== true
                      ? archivo
                      : archivo.replace(/^[^/]*\//, "");

                  return (
                    <ol>
                      <li className="list-group-item" key={index}>
                        <div
                          className={`botono-icono ${
                            extension === "" ? "carpato" : ""
                          }`}
                          onClick={() =>
                            extension === "" ? navegarPath(pathCarpeta) : null
                          }
                        >
                          {icono}

                          <Tooltip content={nombreElemento}>
                            {nombreElemento === "10 Documentación general"
                              ? "Documentación general"
                              : truncarCadena(nombreElemento, 25)}
                          </Tooltip>
                        </div>
                        <div className="botonacos">
                          {archivo !== nombreDocumentacionGeneral &&
                            archivo !== nombreJuntasPropietarios &&
                            !extension &&
                            docusUsuario != "true" && (
                              <MultiSelect
                                overrideStrings={locationSelector}
                                options={rolesOptions}
                                value={rolesAsignados}
                                onChange={(e) =>
                                  asignarSoloPresi(e, pathCarpeta)
                                }
                                labelledBy="Select"
                              />
                            )}
                          {archivo !== nombreDocumentacionGeneral &&
                            archivo !== nombreJuntasPropietarios &&
                            extension && (
                              <button
                                className="btn btn-outline-secondary btn-sm mx-2"
                                onClick={() =>
                                  handleDescargar(
                                    archivo,
                                    archivosEnCarpetaArborist.path,
                                    firebase
                                  )
                                }
                              >
                                {diccionario.Descargar}
                              </button>
                            )}
                          {archivo !== nombreDocumentacionGeneral &&
                            archivo !== nombreJuntasPropietarios && (
                              <button
                                className="btn btn-outline-primary btn-sm mx-2"
                                onClick={(e) => {
                                  handleMostrarDialogoCambiar(
                                    pathCarpeta,
                                    nombreElemento
                                  );
                                }}
                              >
                                Modificar
                              </button>
                            )}
                          {archivo !== nombreDocumentacionGeneral &&
                            archivo !== nombreJuntasPropietarios && (
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={() => {
                                  const dentroDeDocumentacionGeneral = dentroDeDocuGeneral(pathCarpeta);
                                  if (extension !== "") {
                                    //handleBorrarFichero(pathBorrar);//
                                    handleMostrarDialogo(
                                      handleBorrarFichero,
                                      pathCarpeta,
                                      {
                                        titulo:
                                          diccionario.tituloConfirmarBorradoFichero +
                                          archivo,
                                        cuperpomensaje:
                                        !dentroDeDocumentacionGeneral ? diccionario.textoConfirmarBorradoFichero : diccionario.textoConfirmarBorradoFicheroGeneral,
                                      }
                                    );
                                  } else {
                                    handleMostrarDialogo(
                                      handleBorrarCarpeta,
                                      pathCarpeta,
                                      {
                                        titulo:
                                          diccionario.tituloConfirmarBorradoCarpeta +
                                          archivo,
                                        cuperpomensaje:
                                        !dentroDeDocumentacionGeneral ? diccionario.textoConfirmarBorradoCarpeta:diccionario.textoConfirmarBorradoCarpetaGeneral,
                                      }
                                    );
                                  }
                                }}
                              >
                                {diccionario.Borrar}
                              </button>
                            )}
                          <button
                            type="button"
                            className="btn btn-outline-dark btn-sm mx-2"
                            onClick={async () => {
                              if (extension !== "") {
                                setArchivoAvisos(archivo);
                                setPathAvisos(
                                  extraerAntesUltimaBarra(pathCarpeta)
                                );
                                setRolesEnvios([]);
                                modalEnvio();
                              } else {
                                /*
                                await obtenerSoloPresi(
                                  extraerAntesUltimaBarra(pathCarpeta)
                                );*/
                                setRolesEnvios(
                                  rolesAsignados.map((rol) => rol.value)
                                );
                                setPathAvisos(pathCarpeta);
                                modalEnvio();
                                //setRolesEnvios([]);
                              }
                            }}
                          >
                            {diccionario.EnviarAviso}
                          </button>
                        </div>
                      </li>
                    </ol>
                  );
                })}
              </ul>
              <div
                className="drop-area"
                onClick={handleClick}
                style={{
                  borderRadius: "5px",
                  height: "200px",
                  width: "460px",
                  margin: "50px auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="96"
                  height="96"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#3498db"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ marginBottom: "16px" }}
                >
                  <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
                  <line x1="12" y1="8" x2="12" y2="16" />
                  <line x1="8" y1="12" x2="16" y2="12" />
                </svg>

                <p style={{ fontSize: "16px", color: "#555" }}>
                  {diccionario.ArrastraSueltaArchivosSubirlosCarpeta} <br />
                  <strong>
                    {archivosEnCarpetaArborist.carpeta ===
                      Identificador + "/" ||
                    archivosEnCarpetaArborist.carpeta === Identificador
                      ? "Raíz"
                      : archivosEnCarpetaArborist.carpeta ===
                        "10 Documentación general"
                      ? "Documentación general"
                      : archivosEnCarpetaArborist.carpeta}
                  </strong>
                </p>
                <i style={{ color: "red" }}>
                  * {diccionario.AvisoDocumentosSubidosC365}
                </i>
              </div>
            </div>
          </div>
        ) : (
          <h5>{diccionario.SeleccionarCarpetaMostrarDetalle}</h5>
        )}

        <Emulador
          archivosEnCarpetaArborist={archivosEnCarpetaArborist}
          Identificador={Identificador}
          docusUsuario={docusUsuario}
        />
      </div>
      <div
        className="row form-actions-fixed"
        id="footerInsert"
        style={{ background: "transparent" }}
      >
        <div
          className="text-left px-0 col-1"
          id="footerInsertVolver"
          style={{ marginBottom: "10px" }}
        >
          <BackButton />
        </div>{" "}
      </div>

      <ModalComponent
        top={"40%"}
        alturaCustom={"50%"}
        tituloModal={diccionario.EnvioAvisoPrueba}
        cuerpoModal={
          <FormularioEnvioAvisos
            comunidadesSeleccionadas={[{ Id: Identificador }]}
            roles={rolesEnvios}
            abrirDiagolo={abrirDiagolo}
            cerrarDiagolo={() => setModalOpen(false)}
            archivo={archivoAvisos}
            pathCarpeta={pathAvisos}
          />
        }
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        closeEnvios={true}
      />
    </div>
  );
};

export default Documentos;
