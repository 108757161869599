import React, { useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { useParams } from "react-router-dom";

export const Tutorial = () => {
  const { Identificador } = useParams();
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const [expandedItem, setExpandedItem] = useState(null);
  const navigate = useNavigate();
  const circleBadgeStyle = {
    display: "inline-block",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#172983",
    color: "#fff",
    textAlign: "center",
    lineHeight: "30px",
    marginRight: "15px",
    marginLeft: "15px",
  };

  const handleItemClick = (index) => {
    setExpandedItem((prevItem) => (prevItem === index ? null : index));
  };
  const routeDetalle = `${ROUTES.DETALLE_COMUNIDAD}/${Identificador}`;
  const isItemExpanded = (index) => index === expandedItem;

  const pasoTextos = [
    {
      1: diccionario.GeneraCodigoVecinos,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/usuarios?authuser=0#h.ip56v1hw9qda",
    },
    {
      2: diccionario.MarcarActividades,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/actividades",
    },
    {
      3: diccionario.IncidenciasYproveedores,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/proveedores",
    },
    {
      3: diccionario.CompletaDocumentos,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/proveedores#h.5shv4rxo585k",
    },
    {
      5: diccionario.SubeDocumentos,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/documentaci%C3%B3n",
    },
    {
      6: diccionario.CreaVotacionesJuntas,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/juntas",
    },
    {
      7: diccionario.CreaZonasComunes,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/zonas-comunes",
    },
    {
      8: diccionario.HabilitaFichajes,
      descripcion:
        "https://sites.google.com/comunidad365.es/proyecto-manuales/centro-de-ayuda/comunidades/usuarios#h.f6qv6in9ikd2",
    },
  ];

  return (
      <div className="container-detalleRol">
      <p>
        <strong>{diccionario.BienvenidoC365}</strong>
      </p>
      <p>{diccionario.AltaComunidadConfigurar}</p>
      <hr />
      <ol className="list-group">
        {pasoTextos.map((paso, index) => (
          <li key={index} className="list-group-item">
            <div style={circleBadgeStyle} className="mr-2">
              {index + 1}
            </div>
            <span
              className="cursor-pointer"
              onClick={() => handleItemClick(index + 1)}
              style={{ textDecoration: "underline" }}
            >
              {`${diccionario.Paso} ${index + 1}: `}
              {isItemExpanded(index + 1) ? diccionario.OcultarTutorial : diccionario.VerTutorial}
            </span>
            &nbsp;&nbsp;&nbsp; {Object.values(paso)[0] + " "}
            {isItemExpanded(index + 1) && (
              <div
                className="mt-2"
                style={{ marginLeft: "20px" }}
                //   dangerouslySetInnerHTML={{ __html: Object.values(paso)[1] }}
              >
                {" "}
                <a href={paso.descripcion} target="_blank">
                  {diccionario.AbrirEnlacePestanaNueva}
                </a>
              </div>
            )}
          </li>
        ))}
      </ol>
      <div style={{ marginTop: "20px" }}>
        <button className="btn btn-outline-primary" onClick={() => navigate(routeDetalle)}>
          {diccionario.Volver}
        </button>
      </div>
    </div>
  );
};

export default Tutorial;
