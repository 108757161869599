import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { TextField, Checkbox } from "@mui/material";
import { UseLenguageState } from "app/contexts/LenguageContext";
import { useStore } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MenuInicial } from "../home/MenuInicial";
import * as ROUTES from "./../../constants/routes";
import { MenuCentralContenido } from "app/components/MenusCentralContenido/MenuCentralContenido";
import BackButton from "app/components/helpers/BackButton";
import getCustomToastOptions from "app/components/helpers/getCustomToastOptions";
import { useParams } from "react-router-dom";
import { useFirebase } from "app/firebase/context";
import { onValue, ref, update, get, set } from "firebase/database";
import { RefreshButton } from "app/components/helpers/Refresh";
import ModalComponent from "app/components/ModalComponent/ModalComponent";
import Wizard from "../wizard/Wizard";
import { toast } from "react-toastify";
import useAuth from "app/hooks/useAuth";
import FormularioEnvioAvisos from "app/components/ModalComponent/FormularioEnvioAvisos";
import { ListaContratos } from "./ListaContratos";
import { getSemaforoComunidad } from "./FuncionesComunidades";
import { CajaInputs } from "app/components/helpers/CajaInputs";

const initialState = {
  Nombre: "",
  Cif: "",
  Email: "",
  Id: "",
  Fecha_Creacion: "",
  Codigo_seguridad: "",
  Codigo_vecino: "",
  Cp: "",
  Poblacion: "",
  Poliza: "",
  IBAN: "",
  Vecinos: "",
  Pago_App: "",
  key: "",
  Nombre_bus: "",
  Provincia: "",
  Vecino: "",
  Seguro_nombre: "",
  Poliza: "",
  EmailsVerificacion: "",
};

export const ComunidadDetalle = () => {
  const [checksOcultarIconos, setChecksOcultarIconos] = useState({
    Inci: true,
    Docu: true,
    Zona: true,
    Junta: true,
    Seguro: true,
    Mante: true,
    Energia: true,
    Sos: true,
  });

  const [isModalOpenAlta, setModalOpenAlta] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [verContratos, setVerContratos] = useState(false);
  const [step, setStep] = useState(1);
  const store = useStore();
  const lengu = store.getState().lenguajes;
  const diccionario = lengu.diccionario;
  const inputRef = useRef();
  const [state, setState] = useState(initialState);
  const location = useLocation();
  const navigate = useNavigate();
  const [guardar, setGuardar] = useState(true);
  const [comunidades, setComunidades] = useState([]);
  const [estructuraLista, setEstructuralista] = useState([]);
  const [pruebasLista, setPruebaLista] = useState([]);
  const firebase = useFirebase();
  let [numRtdos, setNumRtdos] = useState([]);
  const datosIniciales = useRef([]);
  const numVecinosRef = useRef(0);
  const { user } = useAuth();
  const { Identificador } = useParams();
  const DetallesRoute = `${ROUTES.DOCUMENTOS}/${Identificador}`;
  const usuariosRoute = `${ROUTES.USUARIOS}/${Identificador}`;
  const zonasComunesRoute = `${ROUTES.LISTA_ZONASCOMUNES}/${Identificador}`;
  const centrosTrabajoRoute = `${ROUTES.CENTROS_DE_TRABAJO}/${Identificador}`;
  const actividadesRoute = `${ROUTES.ACTIVIDADES}/${Identificador}`;
  const tituloEnvioAvisos = diccionario.tituloFormAvisos;
  const [contenido, setContenido] = useState([{}]);
  const [codigoRegistroDespacho, setCodigoRegistroDespacho] = useState();
  const [codigoRegistroComunidad, setCodigoRegistroComunidad] = useState();
  const refresh = () => {
    toast.info(diccionario.datosRefrescados, getCustomToastOptions(toast, "info"));
    setRefrescar(!refrescar);
  };
  const [contrato, setContrato] = useState();
  const [refrescar, setRefrescar] = useState(false);
  const [contratoActual, setContratoActual] = useState();
  const [ciaSeguros, setCiaSeguros] = useState();

  const [erroresCamposRequeridos, setErroresCamposRequeridos] = useState({
    Nombre: false,
  });

  const [communityCodeError, setcommunityCodeError] = useState(false);
  const [communityCodeDulpicated, setcommunityCodeDulpicated] = useState(false);

  const obtenerCiaDeSeguros = async () => {
    try {
      const snapshot = await get(firebase.getReferencia(`Comunidad/${Identificador}`));
      const seguro = snapshot.val().Seguro;

      let nombreCia = "";
      if (seguro) {
        const snapshot2 = await get(firebase.getReferencia(`Gremio/${seguro}`));
        nombreCia = snapshot2.val().Nombre ? snapshot2.val().Nombre : "";
      }

      setCiaSeguros(nombreCia);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  console.log("Checks:", checksOcultarIconos);
  const ObtenerCheckBoxesMenu = async () => {
    try {
      const snapshot = await get(firebase.getReferencia(`Comunidad/${Identificador}/OcultarMenu`));

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        if (checksOcultarIconos[childData]) {
          setChecksOcultarIconos((prevState) => ({
            ...prevState,
            [childData]: false,
          }));
        }
      });

      //setChecksOcultarIconos(boxes);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  console.log("Checks:", checksOcultarIconos);
  useEffect(async () => {
    await ObtenerCheckBoxesMenu();
    await obtenerCiaDeSeguros();
    await ObtenerContratoActual();
    await ObtenerHistoricosContratos();
    await obtenerCodigoRegristroDespacho();
    await obtenerValorRoles();
    let loc = null;
    if (location.state) {
      if (location.state.modalAltaC365) {
        modalEnvio();
      }
      loc = location.state;
      setState({
        ...initialState,
        Nombre: loc.Nombre,
        Nombre_bus: loc.Nombre,
        Cif: loc.Cif,
        CodigoComunidad: loc.CodigoComunidad,
        Email: loc.Email,
        Id: loc.Id,
        Fecha_Creacion: loc.Fecha_Creacion,
        Codigo_seguridad: loc.Codigo_seguridad,
        Codigo_vecino: loc.Codigo_vecino,
        Cp: loc.Cp,
        Poblacion: loc.Poblacion,
        Seguro_nombre: loc.Seguro_nombre,
        Poliza: loc.Poliza,
        IBAN: loc.IBAN,
        Vecinos: loc.Vecinos,
        Pago_App: loc.Pago_App,
        key: loc.key,
        Direccion: loc.Direccion,
        Poblacion: loc.Poblacion,
        Provincia: loc.Provincia,
        Poliza: loc.Poliza,
        EmailsVerificacion: loc.EmailsVerificacion,
        Alta: loc.Alta,
        bloqueada: loc.bloqueada,
      });
      location.state = null;
      idComunidadRef.current = loc.Id;
      numVecinosRef.current = loc.Vecinos;
      //   inputRef.current.focus();
    } else {
      const myRef = firebase.getReferencia(`Comunidad/${Identificador}`);
      let datos = [];
      onValue(
        myRef,
        (snapshot) => {
          snapshot.forEach((childSnapshot) => {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            datos[childKey] = childData;
          });
          loc = datos;
          setState({
            ...initialState,
            Nombre: loc.Nombre,
            Nombre_bus: loc.Nombre,
            Cif: loc.Cif,
            CodigoComunidad: loc.CodigoComunidad,
            Email: loc.Email,
            Id: loc.Id,
            Fecha_Creacion: loc.Fecha_Creacion,
            Codigo_seguridad: loc.Codigo_seguridad,
            Codigo_vecino: loc.Codigo_vecino,
            Cp: loc.Cp,
            Poblacion: loc.Poblacion,
            Seguro_nombre: loc.Seguro_nombre,
            Poliza: loc.Poliza,
            IBAN: loc.IBAN,
            Vecinos: loc.Vecinos,
            Pago_App: loc.Pago_App,
            key: loc.key,
            Direccion: loc.Direccion,
            Poblacion: loc.Poblacion,
            Provincia: loc.Provincia,
            Poliza: loc.Poliza,
            EmailsVerificacion: loc.EmailsVerificacion,
            Alta: loc.Alta,
            bloqueada: loc.bloqueada,
          });
          location.state = null;
          codVecinoInicialRef.current = loc.Codigo_vecino;
          idComunidadRef.current = loc.Id;
          numVecinosRef.current = loc.Vecinos;
          emailsVerifiacionInicialesRef.current = loc.EmailsVerificacion;
        },
        {
          onlyOnce: true,
        }
      );
    }
  }, [refrescar]);

  const obtenerCodigoRegristroDespacho = async () => {
    try {
      const snapshot = await get(firebase.getReferencia(`Despacho`));
      const codigo = snapshot.val().CodigoAdministracion;

      setCodigoRegistroDespacho(codigo);

      const snapshot2 = await get(firebase.getReferencia(`Comunidad/${Identificador}`));
      const codigoComunidad = snapshot2.val().CodigoComunidad;

      setCodigoRegistroComunidad(codigoComunidad);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    }
  };
  const obtenerValorRoles = async () => {
    const contratoComu = await get(
      firebase.getReferencia(`Comunidad/${Identificador}/ContratoActivo`)
    );
    let datos = contratoComu.val();
    if (datos) {
      const planContratado = datos.plan;
      const tablaMenus = [
        {
          id: 1,
          ruta: ROUTES.JUNTASVIRTUALES + "/" + Identificador,
          icono: "comunidad",
          titulo: diccionario.Juntas,
          texto: diccionario.DescJuntas,
        },
        {
          id: 2,
          ruta: ROUTES.LISTA_VOTACIONES + "/" + Identificador,
          icono: "votaciones",
          titulo: diccionario.Votaciones,
          texto: diccionario.DescVotaciones,
        },

        {
          id: 4,
          ruta: DetallesRoute,
          icono: "documentos",
          titulo: diccionario.Documentacion,
          texto: diccionario.DescDocumentacion,
        },
        {
          id: 5,
          ruta: ROUTES.LISTA_INCIDENCIAS + "/" + Identificador,
          icono: "incidencias",
          titulo: diccionario.Incidencias,
          texto: diccionario.DescIncidencias,
        },
        {
          id: 6,
          ruta: actividadesRoute,
          icono: "actividades",
          titulo: diccionario.Actividades,
          texto: diccionario.DescActividades,
        },
        {
          id: 7,
          ruta: usuariosRoute,
          icono: "usuarios",
          titulo: diccionario.Usuarios,
          texto: diccionario.DescUsuarios,
        },
      ];
      switch (planContratado) {
        case "reservas":
          tablaMenus.push({
            id: 2,
            ruta: zonasComunesRoute,
            icono: "reservas",
            titulo: diccionario.ReservasZonasComunes,
            texto: diccionario.GestionReservas,
          });
          break;
        case "horario":
          tablaMenus.push({
            id: 19,
            ruta: centrosTrabajoRoute,
            icono: "centros-trabajo",
            titulo: diccionario.CentrosTrabajo,
            texto: diccionario.CentrosTrabajo,
          });
          break;
        case "total":
          tablaMenus.push({
            id: 2,
            ruta: zonasComunesRoute,
            icono: "reservas",
            titulo: diccionario.ReservasZonasComunes,
            texto: diccionario.GestionReservas,
          });
          tablaMenus.push({
            id: 8,
            ruta: centrosTrabajoRoute,
            icono: "centros-trabajo",
            titulo: diccionario.CentrosTrabajo,
            texto: diccionario.CentrosTrabajo,
          });

          break;
      }

      setContenido(tablaMenus);
    }
  };

  const {
    Nombre_bus,
    fechafin,
    fechainicio,
    estructura,
    pruebas,
    Fecharegistro,
    Codigo_seguridad,
    Codigo_vecino,
    Nombre,
    IBAN,
    Perfil,
    key,
    Id,
    Cif,
    Direccion,
    Cp,
    Poblacion,
    Provincia,
    Vecinos,
    Seguro_nombre,
    Poliza,
    EmailsVerificacion,
  } = state;

  const [showDialogForm, setshowDialogForm] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const codVecinoInicialRef = useRef(state.Codigo_vecino);
  const emailsVerifiacionInicialesRef = useRef(state.EmailsVerificacion);
  const idComunidadRef = useRef(state.Id);
  const abrirDiagolo = () => {
    setshowDialogForm(true);
  };
  const handleChangeSeguridad = (e) => {
    const codigoDespacho = user.despacho.Codigo;
    const codVecFinal = codigoDespacho + Identificador + e.target.value;

    setState((prevState) => ({
      ...prevState,
      Codigo_seguridad: e.target.value,
      Codigo_vecino: codVecFinal,
    }));

    if (codVecFinal !== codVecinoInicialRef.current) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const guardarCheckBoxes = async () => {
    let ocultarMenuses = [];
    Object.entries(checksOcultarIconos).forEach(([clave, valor]) => {
      if (valor == false && clave != "cambiado") {
        ocultarMenuses.push(clave);
      }
    });

    try {
      const comUpdatear = firebase.getReferencia(`Comunidad/${Identificador}/OcultarMenu`);

      await set(comUpdatear, ocultarMenuses);
    } catch (error) {
      toast.error(diccionario.ErrorGuardadoDatos, getCustomToastOptions(toast, "error"));
      console.error("Error al bloquear/desbloquear comunidad", error);
    }
  };
  const handleGuardarCambios = async () => {
    try {
      let hasErrors = false;
      const newFormErrors = { ...erroresCamposRequeridos };

      // Validation
      if (state.Nombre.trim() === "") {
        newFormErrors.Nombre = true;
        hasErrors = true;
      } else {
        newFormErrors.Nombre = false;
      }

      if (hasErrors) {
        setErroresCamposRequeridos(newFormErrors);
        // Muestro toast de error de validación y salgo de la función
        toast.error(diccionario.ErrorCamposRequeridos, getCustomToastOptions(toast, "error"));
        return 0;
      } else {
        setErroresCamposRequeridos({
          Nombre: false,
          email: false,
          Id: false,
          Telefono1: false,
        });
      }
      if (checksOcultarIconos.cambiado) {
        guardarCheckBoxes();
      }

      // Actualizar el estado con la información obtenida
      const updatedData = {
        // Codigo_seguridad: state.Codigo_seguridad,
        //  Codigo_vecino: state.Codigo_vecino,
        // EmailsVerificacion: state.EmailsVerificacion,
        Nombre: state.Nombre,
        CodigoComunidad: state.CodigoComunidad.padStart(3, "0"),
      };
      const comUpdatear = firebase.getReferencia(`Comunidad/${Identificador}`);
      await update(comUpdatear, updatedData);
      setRefrescar(!refrescar);
      toast.success(diccionario.DatosGuardadosCorrectamente, getCustomToastOptions(toast));
    } catch (error) {
      toast.error(diccionario.ErrorGuardadoDatos, getCustomToastOptions(toast, "error"));
      console.error("Error al bloquear/desbloquear comunidad", error);
    }
  };

  const [opcionActual, setOpcionActual] = useState("Inicio");
  const modalEnvio = () => {
    setModalOpen(true);
  };
  const modalVerContratos = () => {
    setVerContratos(true);
  };

  const ObtenerHistoricosContratos = async () => {
    try {
      // Obtener datos de Firebase
      const snapshot = await get(
        firebase.getReferencia(`Comunidad/${Identificador}/HistoricoContratos`)
      );
      let datos = [];

      // Iterar sobre los datos obtenidos
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        childData.idInterno = childKey;

        datos.push(childData);
      });

      setContrato(datos);
    } catch (error) {
      console.log(error);
    }
  };

  const ObtenerContratoActual = async () => {
    try {
      const snapshot = await get(
        firebase.getReferencia(`Comunidad/${Identificador}/ContratoActivo`)
      );

      let contratoActual = {};

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        contratoActual[childKey] = childData;
      });

      setContratoActual(contratoActual);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const modalEnvioAlta = () => {
    setModalOpenAlta(true);
    /* setPlan((prevState) => ({
      ...prevState,
      vecinos: numVecinosRef.current,
    })); */
  };
  const [plan, setPlan] = useState({
    plan: "",
    formapago: "",
    iban: "",
    nombre: Identificador,
    vecinos: numVecinosRef.current,
    tablaPrecios: null,
    precioSelected: null,
  });

  const resetFormContrato = () => {
    setPlan({
      plan: "",
      formapago: "",
      iban: "",
      nombre: Identificador,
      vecinos: numVecinosRef.current,
      tablaPrecios: null,
      precioSelected: null,
    });
  };

  const ChangeIconosCheckboxes = (e, name) => {
    // Usa e.target.checked directamente, ya es un valor booleano
    const nuevoValorCheckbox = e;
    setChecksOcultarIconos((prevState) => ({
      ...prevState,
      [name]: nuevoValorCheckbox,
      cambiado: true,
    }));
    setIsButtonEnabled(true);
  };

  const ChangeCheckboxes = (e, name) => {
    if (!EmailsVerificacion !== emailsVerifiacionInicialesRef.current) {
      setIsButtonEnabled(true);
    }
    // Usa e.target.checked directamente, ya es un valor booleano
    const nuevoValorCheckbox = e.target.checked;

    // Actualiza el estado con el nuevo valor booleano
    setState((prevState) => ({
      ...prevState,
      [name]: nuevoValorCheckbox,
    }));
  };

  const changeNombre = (e) => {
    setIsButtonEnabled(true);
    setState({
      ...state,
      Nombre: e.target.value,
    });
  };

  const changeCommunityCode = async (e) => {
    let newValue = e.target.value;

    const snapshot = await get(firebase.getReferencia(`Comunidad`));
    const communities = snapshot.val();

    let duplicated = false;
    for (const community in communities) {
      if (snapshot.val()[community]["CodigoComunidad"] == newValue.padStart(3, "0")) {
        duplicated = true;
      }
    }

    if (isNaN(newValue)) {
      newValue = newValue.replace(/[a-zA-Z]/, "");
      setcommunityCodeError(true);
      setcommunityCodeDulpicated(false);
      setIsButtonEnabled(false);
    } else if (duplicated == true && newValue != state.CodigoComunidad) {
      setcommunityCodeError(false);
      setcommunityCodeDulpicated(true);
      setIsButtonEnabled(false);
    } else {
      setcommunityCodeError(false);
      setcommunityCodeDulpicated(false);
      setIsButtonEnabled(true);
    }

    setCodigoRegistroComunidad(newValue);
    setState({
      ...state,
      CodigoComunidad: newValue,
    });
  };

  return (
    <div className="container-detallecomunidad">
      <div className="container-detalleGremio">
        <div className="president mb-3" style={{ padding: "5px" }}>
          <h2 style={{ fontWeight: "400", marginLeft: "-6px" }}>
            {diccionario.DetalleComunidad} {getSemaforoComunidad(state, diccionario)}
          </h2>
        </div>
      </div>
      <Grid container spacing={3} className="datos-detalle">
        <Grid className="Buscador" item lg={5} md={5} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Nombre}</label>
          </p>
          <TextField className="disabled" disabled value={Nombre || ""} name="Nombre" />
        </Grid>
        <Grid className="Buscador" item lg={3} md={3} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Cif}</label>
          </p>
          <TextField className="disabled" disabled value={Cif || ""} name="Cif" />
        </Grid>
        <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">IBAN</label>
          </p>
          <TextField className="disabled" disabled value={IBAN || ""} name="Poliza" />
        </Grid>
        <Grid className="Buscador" item lg={5} md={5} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.DireccionPrincipal}</label>
          </p>
          <TextField className="disabled" disabled value={Direccion || ""} name="Direccion" />
        </Grid>
        <Grid className="Buscador" item lg={2} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Cp}</label>
          </p>
          <TextField className="disabled" disabled value={Cp || ""} name="Cp" />
        </Grid>
        <Grid className="Buscador" item lg={3} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Poblacion}</label>
          </p>
          <TextField className="disabled" disabled value={Poblacion || ""} name="Poblacion" />
        </Grid>
        <Grid className="Buscador" item lg={2} md={2} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Provincia}</label>
          </p>
          <TextField className="disabled" disabled value={Provincia || ""} name="Provincia" />
        </Grid>
        <Grid className="Buscador" item lg={2} md={2} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Vecinos}</label>
          </p>
          <TextField className="disabled" disabled value={Vecinos || ""} name="Vecinos" />
        </Grid>
        <Grid className="Buscador" item lg={5} md={5} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Seguro_nombre}</label>
          </p>
          <TextField className="disabled" disabled value={ciaSeguros || ""} name="ciaSeguros" />
        </Grid>
        <Grid className="Buscador" item lg={5} md={5} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.Poliza}</label>
          </p>
          <TextField className="disabled" disabled value={Poliza || ""} name="Poliza" />
        </Grid>
        <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
          <p>
            <label className="strong-label">{diccionario.CodigoDespacho}</label>
          </p>
          <TextField
            className="disabled"
            disabled
            value={codigoRegistroDespacho || ""}
            name="codigoRegistroDespacho"
          />
        </Grid>
        <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
          <p style={{ whiteSpace: "nowrap" }}>
            <label className="strong-label">{diccionario.CodigoComunidad}</label>
          </p>
          <TextField
            onChange={async (e) => await changeCommunityCode(e)}
            value={codigoRegistroComunidad || ""}
            name="codigoRegistroComunidad"
            inputProps={{ maxLength: 3 }}
            helperText={
              communityCodeError == true
                ? diccionario.CodigoDebeSerNumero
                : communityCodeDulpicated == true
                ? diccionario.ElCodigo +
                  codigoRegistroComunidad.padStart(3, "0") +
                  diccionario.CoincideConOtraComunidad
                : ""
            }
            error={communityCodeError || communityCodeDulpicated}
          />
        </Grid>
        <Grid className="Buscador" item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
          <p style={{ whiteSpace: "nowrap" }}>
            <label className="strong-label">{diccionario.Codigo_vecino}</label>
          </p>
          <TextField
            className="disabled"
            disabled
            value={codigoRegistroDespacho + codigoRegistroComunidad || ""}
            name="CodigoVecino"
          />
        </Grid>{" "}
        <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
          {" "}
        </Grid>
        <Grid className="Buscador acciones" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
          <CajaInputs
            titulo={diccionario.Acciones}
            contenido={
              <div
                class="contenedorAdmin contenedorAdminHome"
                style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
              >
                <div className="cards">
                  <div>
                    <a onClick={() => modalEnvio()}>
                      <i class="icon-avisos"></i>
                      <h5> {diccionario.ENVIAR_AVISO}</h5>
                      <p>Enviar notificacion/email a los vecinos</p>
                    </a>
                  </div>
                  <div>
                    <a onClick={() => modalVerContratos()}>
                      <i class="icon-contratos"></i>
                      <h5> {diccionario.VerContratos}</h5>
                      <p>Descargar/Visualizar contratos</p>
                    </a>
                  </div>
                  <div>
                    <a onClick={() => modalVerContratos()}>
                      <i class="icon-facturas"></i>
                      <h5> {diccionario.VerFacturas}</h5>
                      <p>Descargar/Visualizar facturas</p>
                    </a>
                  </div>
                  <div>
                    <a onClick={() => navigate("/tutorial" + "/" + Identificador)}>
                      <i class="icon-tutorial"></i>
                      <h5> {diccionario.VerTutorial}</h5>
                      <p>Primeros pasos</p>
                    </a>
                  </div>
                </div>
              </div>
            }
          />
        </Grid>
        <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
          {" "}
        </Grid>
        <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
          <CajaInputs
            titulo={diccionario.OcultarIconosApp}
            contenido={
              <div className="contenedor-de-check" style={{ display: "flex" }}>
                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Inci == true ? "" : "disabled"}`}
                      onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Inci, "Inci")}
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Inci === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.Incidencias}</h5>
                      <p>
                        {checksOcultarIconos.Inci == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Docu == true ? "" : "disabled"}`}
                      onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Docu, "Docu")}
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Docu === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.Documentacion}</h5>
                      <p>
                        {checksOcultarIconos.Docu == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                {(contratoActual && contratoActual.plan == "total") ||
                (contratoActual && contratoActual.plan == "reservas") ? (
                  <div
                    class="contenedorAdmin contenedorAdminHome"
                    style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                  >
                    <div style={{ width: "100%" }}>
                      <a
                        name="Publicar"
                        className={`${checksOcultarIconos.Zona == true ? "" : "disabled"}`}
                        onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Zona, "Zona")}
                      >
                        <i
                          className={`icon-${
                            checksOcultarIconos.Zona === true ? "checked" : "unchecked"
                          }`}
                          style={{ marginRight: "10px" }} // Add space between the icon and text
                        ></i>
                        <h5>{diccionario.ZonasComunes2}</h5>
                        <p>
                          {checksOcultarIconos.Zona == true
                            ? diccionario.VisibleAPP
                            : diccionario.OcultoAPP}
                        </p>
                      </a>
                    </div>
                  </div>
                ) : null}

                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Junta == true ? "" : "disabled"}`}
                      onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Junta, "Junta")}
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Junta === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.VerJuntas}</h5>
                      <p>
                        {checksOcultarIconos.Junta == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Seguro == true ? "" : "disabled"}`}
                      onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Seguro, "Seguro")}
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Seguro === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.Seguro}</h5>
                      <p>
                        {checksOcultarIconos.Seguro == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Energia == true ? "" : "disabled"}`}
                      onClick={(e) =>
                        ChangeIconosCheckboxes(!checksOcultarIconos.Energia, "Energia")
                      }
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Energia === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.Energia}</h5>
                      <p>
                        {checksOcultarIconos.Energia == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Mante == true ? "" : "disabled"}`}
                      onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Mante, "Mante")}
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Mante === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.Mantenimiento}</h5>
                      <p>
                        {checksOcultarIconos.Mante == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                <div
                  class="contenedorAdmin contenedorAdminHome"
                  style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
                >
                  <div style={{ width: "100%" }}>
                    <a
                      name="Publicar"
                      className={`${checksOcultarIconos.Sos == true ? "" : "disabled"}`}
                      onClick={(e) => ChangeIconosCheckboxes(!checksOcultarIconos.Sos, "Sos")}
                    >
                      <i
                        className={`icon-${
                          checksOcultarIconos.Sos === true ? "checked" : "unchecked"
                        }`}
                        style={{ marginRight: "10px" }} // Add space between the icon and text
                      ></i>
                      <h5>{diccionario.BotonSOS}</h5>
                      <p>
                        {checksOcultarIconos.Sos == true
                          ? diccionario.VisibleAPP
                          : diccionario.OcultoAPP}
                      </p>
                    </a>
                  </div>
                </div>

                {/*<Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p> {diccionario.Incidencias}</p>
                  <Checkbox
                    name="Inci"
                    checked={checksOcultarIconos.Inci || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Inci")}
                  ></Checkbox>
                </Grid>
                <Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p> {diccionario.Documentacion}</p>
                  <Checkbox
                    name="Docu"
                    checked={checksOcultarIconos.Docu || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Docu")}
                  ></Checkbox>
                </Grid>
                {(contratoActual && contratoActual.plan == "total") ||
                (contratoActual && contratoActual.plan == "reservas") ? (
                  <Grid className="Buscador" item lg={1.2} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                    <p>{diccionario.ZonasComunes}</p>
                    <Checkbox
                      name="Zona"
                      checked={checksOcultarIconos.Zona || false}
                      color="primary"
                      onChange={(e) => ChangeIconosCheckboxes(e, "Zona")}
                    ></Checkbox>
                  </Grid>
                ) : null}
                <Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p>{diccionario.VerJuntas}</p>
                  <Checkbox
                    name="Junta"
                    checked={checksOcultarIconos.Junta || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Junta")}
                  ></Checkbox>
                </Grid>
                <Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p>{diccionario.Seguro}</p>
                  <Checkbox
                    name="Seguro"
                    checked={checksOcultarIconos.Seguro || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Seguro")}
                  ></Checkbox>
                </Grid>
                <Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p>{diccionario.Energia}</p>
                  <Checkbox
                    name="Seguro"
                    checked={checksOcultarIconos.Energia || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Energia")}
                  ></Checkbox>
                </Grid>
                <Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p>{diccionario.Mantenimiento}</p>
                  <Checkbox
                    name="Mante"
                    checked={checksOcultarIconos.Mante || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Mante")}
                  ></Checkbox>
                </Grid>
                <Grid className="Buscador" item lg={1} md={1} sm={12} xs={12} sx={{ mt: 0 }}>
                  <p>{diccionario.BotonSOS}</p>
                  <Checkbox
                    name="Sos"
                    checked={checksOcultarIconos.Sos || false}
                    color="primary"
                    onChange={(e) => ChangeIconosCheckboxes(e, "Sos")}
                  ></Checkbox>
                </Grid>{" "} */}
              </div>
            }
          />
        </Grid>
        <Grid className="Buscador" item lg={12} md={12} sm={12} xs={12} sx={{ mt: 0 }}>
          {" "}
        </Grid>
      </Grid>
      <ModalComponent
        top={"40%"}
        alturaCustom={"50%"}
        tituloModal={tituloEnvioAvisos}
        cuerpoModal={
          <FormularioEnvioAvisos
            comunidadesSeleccionadas={[{ Id: Identificador }]}
            abrirDiagolo={abrirDiagolo}
            cerrarDiagolo={() => setModalOpen(false)}
          />
        }
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        closeEnvios={true}
      />

      <ModalComponent
        top={"40%"}
        alturaCustom={"50%"}
        tituloModal={diccionario.ListaContratos}
        cuerpoModal={<ListaContratos contrato={contrato} contratoActivo={contratoActual} />}
        isModalOpen={verContratos}
        setModalOpen={setVerContratos}
        closeEnvios={true}
      />
      <div className="detallecomunidad-servicios" style={{ marginTop: "10px" }}>
        <CajaInputs
          titulo={diccionario.Servicios}
          contenido={<MenuCentralContenido contenido={contenido} activarBuscador={false} />}
        />

        <div className="row form-actions-fixed" id="footerInsert">
          <hr className="col-12 p-0 m-0 mb-3" id="LineBottom"></hr>
          <div className="text-left px-0 col-6">
            <BackButton />
            <RefreshButton onRefresh={refresh} />
          </div>
          <div className="text-right px-0 col-5">
            <button
              disabled={!isButtonEnabled}
              onClick={() => handleGuardarCambios()}
              className="btn btn-outline-primary mx-2"
            >
              {diccionario.Guardar}
            </button>
          </div>
        </div>

        <ModalComponent
          step={step}
          setStep={setStep}
          alturaCustom={step < 4 ? "45%" : "600px"}
          anchuraCustom={"85%"}
          tituloModal={diccionario.ProcesoAltaComunidad}
          alturaContenido={step === 4 ? "500px" : ""}
          cuerpoModal={
            <Wizard
              Comunidad={Identificador}
              Vecinos={120}
              step={step}
              setStep={setStep}
              isModalOpen={isModalOpenAlta}
              setModalOpen={setModalOpen}
              plan={plan}
              setPlan={setPlan}
              resetFormContrato={resetFormContrato}
              datosCompletosComunidad={state}
            />
          }
          isModalOpen={isModalOpenAlta}
          setModalOpen={setModalOpenAlta}
        />
      </div>
    </div>
  );
};
export default ComunidadDetalle;
